import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import axios from 'axios';
import { baseUrl } from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail } from '../../redux/Features/Auth';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [message, setMessage] = React.useState("");
    const { auth } = useSelector((state) => state.auth);

    const handleClick = async () => {
        try {
            const res = await axios.post(`${baseUrl}/auth.php?action=reset`, {email: auth.email });
            console.log(res);
            setMessage("Password reset link sent! Check your email.");
            navigate("/auth/otp");
        } catch (error) {
            console.error(error);
            setMessage("Error: Could not send reset link. Try again later.");
        }
    };

    return (
        <main className="w-screen md:h-screen flex items-center justify-center">
            <div className="h-full flex items-center justify-center p-10">
                <form className="w-full">
                    <div className="flex items-center gap-2">
                        <Link to="/auth/login" className="p-2 text-[#8F1E63] bg-[#8F1E63] bg-opacity-10 rounded-md ">
                            <IoArrowBack />
                        </Link>
                        <p className="text-[#8F1E63] font-semibold">Go back</p>
                    </div>
                    <div className="w-full flex flex-col gap-10 py-10">
                        <div>
                            <h2 className="font-bold capitalize text-3xl">Forgot Password</h2>
                            <p className="font-semibold mt-2">Enter your email address to reset your password.</p>
                        </div>

                        <input 
                            type="email" 
                            onChange={(e) => dispatch(setEmail(e.target.value))} 
                            className="border-2 border-[#8F1E63] text-[#8F1E63] placeholder:text-[#8F1E63] p-2 rounded-lg" 
                            placeholder="Enter Email" 
                            required
                        />

                        {message && <p className="text-center text-sm text-red-600">{message}</p>}

                        <button 
                            type="button" 
                            onClick={handleClick} 
                            className="flex justify-center items-center gap-1 font-bold capitalize bg-[#8F1E63] text-white w-full p-2 rounded-md"
                        >
                            Reset Password
                        </button>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default ForgotPassword;
