import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import axios from 'axios';
import { baseUrl } from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword } from '../../redux/Features/Auth';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const { auth } = useSelector((state) => state.auth);

  const handleClick = async (e) => {
    e.preventDefault();

    if (auth.password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const res = await axios.post(`${baseUrl}/auth.php?action=updatePassword`, { 
        email: auth.email,
        password: auth.password
       });
      console.log(res);
      navigate("/auth/login");
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to reset password. Please try again.");
    }
  }

  return (
    <main className="w-screen md:h-screen flex items-center justify-center">
      <div className="h-full flex items-center justify-center p-10">
        <form className="w-full" onSubmit={handleClick}>
          <div className="flex items-center gap-2">
            <Link to="/auth/forgotpassword" className="p-2 text-[#8F1E63] bg-[#8F1E63] bg-opacity-10 rounded-md ">
              <IoArrowBack />
            </Link>
            <p className="text-[#8F1E63] font-semibold">Go back</p>
          </div>
          <div className="w-full flex flex-col gap-10 py-10">
            <div>
              <h2 className="font-bold capitalize text-3xl">Reset password</h2>
              <p className="font-semibold capitalize mt-2">Enter new password to proceed.</p>
            </div>

            <div className="flex flex-col gap-3">
              <input
                type="password"
                className="border-2 w-full border-[#8F1E63] text-[#8F1E63] placeholder:text-[#8F1E63] p-2 rounded-lg"
                placeholder="Enter new password"
                onChange={(e) => dispatch(setPassword(e.target.value))}
                required
              />
              <input
                type="password"
                className="border-2 w-full border-[#8F1E63] text-[#8F1E63] placeholder:text-[#8F1E63] p-2 rounded-lg"
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

            <button
              type="submit"
              className="flex justify-center items-center gap-1 font-bold capitalize bg-[#8F1E63] text-white w-full p-2 rounded-md"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

export default ResetPassword;
